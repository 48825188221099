<template>
    <div class="code-wrapper w-100">
        <div class="code-wrapper__inner w-100">
            <slot />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@mixin code-colors($colors) {
    @each $token, $color in $colors {
        .token.#{$token} {
            color: $color;
        }
    }
}

.code-wrapper :deep(.code-wrapper__inner) {
    code[class*='language-'],
    pre[class*='language-'] {
        background: none;
        display: block;
        font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        text-shadow: 0 1px rgba(0, 0, 0, 0.3);
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;

        @include prefix(tab-size, 4);
        @include prefix(hyphens, none);
    }
    pre[class*='language-'] {
        padding: 15px 0 15px 0;
        margin: 0;
        overflow: auto;
        position: relative;
        width: 100%;
    }
    code[class*='language-'] {
        padding: 0 15px 0 50px;
        position: relative;
        display: block;
    }
    :not(pre) > code[class*='language-'],
    pre[class*='language-'] {
        background: #1e1e1e;
    }
    :not(pre) > code[class*='language-'] {
        padding: 0.1em;
        border-radius: 0.3em;
        white-space: normal;
    }
    $colors-javascript: (
        'class-name': #4dc9b0,
        'comment': #699956,
        'boolean': #569cd6,
        'function': #dcdcaa,
        'keyword': #569cd6,
        'number': #ebebc2,
        'parameter': #9ddcfe,
        'property': #9ddcfe,
        'punctuation': #179fff,
        'regex': #cd6d62,
        'string': #c88e75,
    );
    $colors-php: (
        'keyword': #569cd6,
        'package': #4dc9b0,
        'class-name': #4dc9b0,
        'variable': #9ad8f8,
        'function': #dcdcaa,
        'comment': #699956,
        'property': #9ddcfe,
        'string': #c88e75,
        'number': #ebebc2,
        'constant': #d4d4d4,
        'operator': #d4d4d4,
        'punctuation': #179fff,
    );
    $colors-html: (
        'tag': #5395cd,
        'attr-name': #9ddcfe,
        'attr-value': #c88e75,
    );
    $colors-css: (
        'atrule .rule': #bc80b7,
        'atrule .keyword': #d4d4d4,
        'property': #9ddcfe,
        'punctuation': #d66fd3,
    );
    .language-json,
    .language-javascript,
    .language-typescript {
        color: #9ddcfe;
        @include code-colors($colors-javascript);
    }
    .language-php {
        @include code-colors($colors-php);
    }
    .language-html,
    .language-vue,
    .language-xml {
        color: #d4d4d4;
        @include code-colors($colors-html);
    }
    .language-css {
        color: #b1c9a5;
        @include code-colors($colors-css);
    }
    .language-txt,
    .language-htaccess,
    .language-console {
        color: #d4d4d4;
    }
    .language-sql {
        color: #569cd6;
    }
}
</style>
