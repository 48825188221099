<template>
    <ul class="link-list font-medium font-uppercase">
        <slot />
    </ul>
    <div class="cl" />
</template>

<style lang="scss" scoped>
.link-list {
    border-top: 1px solid color('black');
    padding-bottom: 20px;

    :deep(li) .app-link {
        border-bottom: 1px solid color('black');
        font-size: 14px;
        font-weight: 600;
        line-height: 40px;
        padding: 5px 0;
        text-decoration: none;

        &.router-link-active {
            color: color('white');
        }

        .base-icon {
            margin: 0;
            @include transition();
        }
        &:hover {
            color: color('white');

            .base-icon {
                margin-left: 10px;
            }
        }
        .app-image {
            float: left;
            margin: 0 15px 0 0;
        }
    }

    /*
    li.t {
        display: inline-block;
        padding: 10px 20px 10px 0px;
        @include transition(all, 0.4s, cubic-bezier(0.5, 1, 1, 1.8));

        &:hover {
            transform: translateY(-10px); @todo
        }
    } @todo
    */
}
</style>
